.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: a from global;
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: buttonPrimary from global;
}
.headingPayoutMissing {
  font-size: 24px;
}
.popupHeading {
  font-size: 18px;
  line-height: 1.4;
}
.signupLink {
  border: 1px solid var(--marketplaceColor);
  border-radius: 4px;
  color: var(--marketplaceColor);
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: var(--transitionStyleButton);
  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    text-decoration: none;
  }
}
