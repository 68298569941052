.root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.helloBarContainer {
  background-color: #272727;
  width: 100%;
  padding: 4px 0;
  display: none;
}
.helloBar {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
  width: 100%;
  /* margin: 0.1rem auto; */
  font-size: 0.9rem;
  line-height: 1.7rem;
  padding: 0.15rem 2rem;
  @media (--viewportMedium) {
    padding: 0.15rem 6rem;
  }
  padding-bottom: 2px;
  /* border-bottom: 1px solid gainsboro; */
}
.helloBar a {
  /* color: rgb(250, 250, 250); */
  font-weight: 400;
  color: #bbb;
}
.icon {
  font-size: 20px;
  margin-left: 0.5rem;
  color: #8a8a8a;
}
