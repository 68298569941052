@import '../../styles/customMediaQueries.css';
.root {
}

.inputError {
  border: var(--borderErrorField);
}
.subHeading {
  margin: 0;
  color: var(--colorGrey400);
  margin-bottom: 0.5rem;
}
.label {
  padding-bottom: 0;
}
.socialContainer {
  display: flex;
  align-items: center;
}
.social {
  border: 1px solid var(--colorGrey100);
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 5px 0px 0px 5px;
  border-right: 0;
  @media (--viewportMedium) {
    padding: 0.75rem 0.5rem;
  }
}
.socialInput {
  border: 1px solid var(--colorGrey100);
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 0px 5px 5px 0px;
}
